@import '@fontsource/inter';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}

.tab-underline-selected::before {
  content: '';
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  right: 0;
  height: 0.2rem;
  background: #706eff;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.tab-underline:hover::before {
  content: '';
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  right: 0;
  height: 0.2rem;
  background: #9ca3af;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
