.inputSearch {
  padding: 0 !important;
  border: none !important;
}

.inputSearch input {
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 30px !important;
}

.inputSearch div[role='button'] {
  background-color: #706eff !important;
  border-color: #706eff !important;
  padding-top: 3px !important;
}

.inputSearch button[role='button'] {
  padding-bottom: 3px !important;
}

.inputSearch ul li span b:nth-child(2) {
  color: #706eff !important;
}
