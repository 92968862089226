.nav-li-selected {
  background-color: #f6e6ee;
  outline: 0.1rem solid transparent;
  position: relative;
  text-decoration: none;
}
.nav-li-selected::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0.6rem;
  height: 100%;
  width: 0.3rem;
  background-color: #dda6c3;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.nav-icon-filter-selected {
  filter: invert(42%) sepia(76%) saturate(487%) hue-rotate(276deg)
    brightness(82%) contrast(87%);
}
